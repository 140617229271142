import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import Unicon from 'vue-unicons'
import { uniLinkedin, uniPhone, uniMap,uniFileDownload, uniBars, uniTimes } from 'vue-unicons/src/icons'

Unicon.add([uniLinkedin, uniPhone, uniMap, uniFileDownload, uniBars, uniTimes])

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(Unicon)

export const bus = new Vue();

const init = async () => { 
  await store.dispatch('init');
  await store.dispatch('getNavItems');

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

init();
