import Vue from 'vue'
import Vuex from 'vuex'
import StoryblokClient from 'storyblok-js-client';

Vue.use(Vuex)

export interface RootState {
  categories: any[];
  pages: any[];
  navItems: any[];
  storyblok: StoryblokClient | null;
}

export default new Vuex.Store<RootState>({
  state: {
    categories: [], 
    pages: [], 
    navItems: [], 
    storyblok: null
  },
  mutations: {
    setStorkblok(state, s: StoryblokClient){
      state.storyblok = s;
    },
    setNavItems(state, navItems: any[]){
      state.navItems = navItems;
    },
    setCategories(state, categories: any[]){
      state.categories = categories;
    },
    setPages(state, pages: any[]){
      state.pages = pages;
    }
  },
  actions: {
    init(ctx){ 
      const s = new StoryblokClient({
        accessToken: '47QoeNqTE5FDEWoUZ56FfQtt',
        cache: {
          clear: 'auto',
          type: 'memory'
        }
      }); 
      ctx.commit('setStorkblok',s);
    },
    async getNavItems(ctx){
      if (!ctx.state.storyblok){
        return;
      }
      const response = await ctx.state.storyblok.get(`cdn/stories/`, {
        "starts_with":"nav/", 
        "sort_by":"position:asc"
      });
      console.log('navitem response', response);

      const navItems = response.data.stories;
      ctx.commit('setNavItems', navItems);
    },
    async getItem(ctx, path: string){ 
      if (!ctx.state.storyblok){
        return;
      }
      console.log('getting item', path);
      const response = await ctx.state.storyblok.get(`cdn/stories/${path}`);
      return response.data.story;
    },
    async getSubCategories(ctx, catId: string){ 
      if (!ctx.state.storyblok){
        return;
      }
      const filter = { 
        "sort_by": "position:asc",
        
        "filter_query": {
          "parent_category.id":{
            "in": catId
          },
          "component": {
            "in": `category`
          }
        },
        "excluding_fields": "body,category"
      };
      console.log('getting sub items', filter);
      
      const response = await ctx.state.storyblok.get(`cdn/stories/`, filter);
      return response.data.stories;
    },
    async getSubItems(ctx, catId: string){ 
      if (!ctx.state.storyblok){
        return;
      }
      const filter = { 
        "sort_by": "position:asc",
        
        "filter_query": {
          "category.id":{
            "in": catId
          },
          "component": {
            "in": `post`
          }
        },
        "excluding_fields": "body,category"
      };
      console.log('getting sub items', filter);
      
      const response = await ctx.state.storyblok.get(`cdn/stories/`, filter);
      return response.data.stories;
    },

    async getPage(ctx, slug: string) { 
      if (!ctx.state.storyblok){
        return;
      }

      const response = await ctx.state.storyblok.get(`cdn/stories/pages/${slug}`);
      return response.data.story;
    },

    async getPost(ctx, slug: string) { 
      if (!ctx.state.storyblok){
        return;
      }

      const response = await ctx.state.storyblok.get(`cdn/stories/posts/${slug}`);
      return response.data.story;
    }
  },
  modules: {
  }
})
