






















import { Component, Vue } from 'vue-property-decorator';
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import WhoWeAre from '@/components/WhoWeAre.vue';
import { bus } from './main';

@Component({
  components:{
    SiteHeader,
    SiteFooter, 
    WhoWeAre
  }
})
export default class App extends Vue {
  busy = true;
  mounted(){ 
    bus.$on('showBusy', ()=>{ this.busy = true; });
    bus.$on('hideBusy', ()=>{ this.busy = false; });
  }
}
